require('./scss/blog.scss');

require('jquery/dist/jquery.min.js');
require('foundation-sites/dist/js/plugins/foundation.core.js');
require('foundation-sites/dist/js/plugins/foundation.dropdown.js');
require('foundation-sites/dist/js/plugins/foundation.dropdownMenu.js');
require('foundation-sites/dist/js/plugins/foundation.responsiveMenu.js');
require('foundation-sites/dist/js/plugins/foundation.responsiveToggle.js');
require('foundation-sites/dist/js/plugins/foundation.util.mediaQuery.js');

require('./js/app-blog.js');

if (module.hot) {
    module.hot.accept();
}
